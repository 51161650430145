import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#2b5f2c",
        secondary: "#0d1c15",
        accent: "#0d1c15",
        error: "#dc3545",
        info: "#072440",
        success: "#28a745",
        warning: "#ffc107",
      },
    },
  },
});
