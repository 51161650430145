import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

router.beforeEach((to, _from, next) => {
  document.title = to.meta?.title
    ? `Budget-Movers - ${to.meta?.title}`
    : "Budget-Movers";
  next();
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
