
import Vue from "vue";
import NewQuoteForm from "./NewQuoteForm.vue";

export default Vue.extend({
  components: { NewQuoteForm },
  name: "NewQuoteDialog",
  data() {
    return {
      loading: false,
      errorMessage: "",
      isSaveEnabled: false,
    };
  },
  methods: {
    async submitQuoteRequest() {
      if (this.$refs.newQuoteForm) {
        this.loading = true;
        const quoteForm = this.$refs.newQuoteForm as InstanceType<
          typeof NewQuoteForm
        >;

        await quoteForm.submitQuoteRequest();
        this.loading = false;
      }
    },
    closeDialog(): void {
      this.$emit("close");
    },
    onErrorMessageChanged(value: string) {
      this.errorMessage = value;
    },
    onSaveEnableDisable(value: boolean) {
      this.isSaveEnabled = value;
    },
    onQuoteSubmitted() {
      this.$emit("quote-submitted");
      this.closeDialog();
    },
  },
});
