export enum MovingType {
  Residential = 1,
  Commercial = 2,
  OfficeRelocation = 3,
  JunkRemoval = 4,
  Other = 5,
}

export class MovingTypeToDescription {
  public static GetDescription(value: MovingType) {
    switch (value) {
      case MovingType.Residential:
        return "Residential";
      case MovingType.Commercial:
        return "Commercial";
      case MovingType.OfficeRelocation:
        return "Office relocation";
      case MovingType.JunkRemoval:
        return "Junk Removal";
      case MovingType.Other:
        return "Other";
      default:
        return "Unknown Moving Type";
    }
  }
}
