export enum ResidentialMovingType {
  OneBedroom = 1,
  TwoBedroom = 2,
  ThreeBedroom = 3,
  FourBedroom = 4,
  Other = 5,
}

export class ResidentialTypeToDescription {
  public static GetDescription(value: ResidentialMovingType) {
    switch (value) {
      case ResidentialMovingType.OneBedroom:
        return "1 Bedroom house";
      case ResidentialMovingType.TwoBedroom:
        return "2 Bedroom house";
      case ResidentialMovingType.ThreeBedroom:
        return "3 Bedroom house";
      case ResidentialMovingType.FourBedroom:
        return "4 Bedroom house";
      case ResidentialMovingType.Other:
        return "4+ Bedroom house";
      default:
        return "Unknown Moving Type";
    }
  }
}
