
import Vue from "vue";
import NewQuoteDialog from "@/components/NewQuoteDialog.vue";

export default Vue.extend({
  name: "App",
  components: { NewQuoteDialog },
  data() {
    return {
      drawer: false,
      showSuccessSnackbar: false,
      showQuoteDialog: false,
      currentYear: new Date().getFullYear(),
      links: [
        {
          title: "Inter City Moving",
          link: "/inter-city-moving",
        },
        {
          title: "North Island Destinations",
          link: "/service-destinations",
        },
        {
          title: "Best Price Guarantee",
          link: "/moving-prices",
        },
        {
          title: "About us",
          link: "/about",
        },
        {
          title: "Services",
          link: "/services",
        },
      ],
    };
  },
});
