import { getFirestore } from "firebase/firestore";
import firebaseConfig from "./firebaseConfig";
import { initializeApp } from "firebase/app";

const app = initializeApp(firebaseConfig);

// Create a Firestore instance
const db = getFirestore(app);

export { db };
