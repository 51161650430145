import { MovingType } from "./enums/moving-type";
import { ResidentialMovingType } from "./enums/residential-moving-type";
import { Timestamp } from "firebase/firestore";

export class Quote {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  movingDate: Timestamp;
  locationFrom: string;
  locationTo: string;
  movingType: MovingType;
  residentialMovingType?: ResidentialMovingType;
  description: string;

  constructor(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    movingDate: Timestamp,
    locationFrom: string,
    locationTo: string,
    movingType: MovingType,
    description: string,
    residentialMovingType?: ResidentialMovingType
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.movingDate = movingDate;
    this.locationFrom = locationFrom;
    this.locationTo = locationTo;
    this.movingType = movingType;
    this.residentialMovingType = residentialMovingType;
    this.description = description;
  }
}
