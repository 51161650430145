
import { MovingType, MovingTypeToDescription } from "@/api/enums/moving-type";
import {
  ResidentialMovingType,
  ResidentialTypeToDescription,
} from "@/api/enums/residential-moving-type";
import { Quote } from "@/api/quote";
import QuoteApi from "@/api/quoteApi";
import Vue from "vue";
import { Timestamp } from "firebase/firestore";

export default Vue.extend({
  name: "NewQuoteForm",
  computed: {
    movingTypeEnum() {
      return MovingType;
    },
    formattedMovingDate(): string {
      if (this.movingDate) {
        const d = new Date(this.movingDate);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [day, month, year].join("/");
      }
      return "";
    },
    isSaveEnabled(): boolean {
      if (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.phone &&
        this.movingDate &&
        this.locationFrom &&
        this.locationTo &&
        this.movingType
      ) {
        if (
          this.movingType === MovingType.Residential &&
          !this.residentailMovingType
        ) {
          return false;
        }

        if (this.movingType === MovingType.Other && !this.description) {
          return false;
        }

        return true;
      }
      return false;
    },
  },
  data() {
    return {
      todaysDate: new Date().toISOString(),
      showDatePicker: false,
      valid: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      movingDate: "",
      locationFrom: "",
      locationTo: "",
      description: "",
      movingType: 0,
      movingTypes: [
        {
          value: MovingType.Residential,
          text: MovingTypeToDescription.GetDescription(MovingType.Residential),
        },
        {
          value: MovingType.Commercial,
          text: MovingTypeToDescription.GetDescription(MovingType.Commercial),
        },
        {
          value: MovingType.OfficeRelocation,
          text: MovingTypeToDescription.GetDescription(
            MovingType.OfficeRelocation
          ),
        },
        {
          value: MovingType.JunkRemoval,
          text: MovingTypeToDescription.GetDescription(MovingType.JunkRemoval),
        },
        {
          value: MovingType.Other,
          text: MovingTypeToDescription.GetDescription(MovingType.Commercial),
        },
      ],
      residentailMovingType: 0,
      residentailMovingTypes: [
        {
          value: ResidentialMovingType.OneBedroom,
          text: ResidentialTypeToDescription.GetDescription(
            ResidentialMovingType.OneBedroom
          ),
        },
        {
          value: ResidentialMovingType.TwoBedroom,
          text: ResidentialTypeToDescription.GetDescription(
            ResidentialMovingType.TwoBedroom
          ),
        },
        {
          value: ResidentialMovingType.ThreeBedroom,
          text: ResidentialTypeToDescription.GetDescription(
            ResidentialMovingType.ThreeBedroom
          ),
        },
        {
          value: ResidentialMovingType.FourBedroom,
          text: ResidentialTypeToDescription.GetDescription(
            ResidentialMovingType.FourBedroom
          ),
        },
        {
          value: ResidentialMovingType.Other,
          text: ResidentialTypeToDescription.GetDescription(
            ResidentialMovingType.Other
          ),
        },
      ],
      emailRules: [
        (v: string) => !!v || "E-mail is required",
        (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v: string) => !!v || "This field is required"],
      errorMessage: "",
    };
  },
  watch: {
    errorMessage(value: string) {
      this.$emit("error-message-change", value);
    },
    isSaveEnabled(value: boolean) {
      this.$emit("save-enabled", value);
    },
  },
  methods: {
    async submitQuoteRequest(): Promise<void> {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.errorMessage = "";
        try {
          const specificTimestamp: Timestamp = Timestamp.fromDate(
            new Date(this.movingDate)
          );

          const quote = new Quote(
            this.firstName,
            this.lastName,
            this.email.trim(),
            this.phone,
            specificTimestamp,
            this.locationFrom,
            this.locationTo,
            this.movingType,
            this.description,
            this.residentailMovingType
          );

          await QuoteApi.saveQuote(quote);
          this.$emit("quote-submitted");
          this.initForm();
        } catch (error) {
          this.errorMessage = "Something went wrong, please try again!";
        }
      }
    },
    initForm() {
      (this.$refs.form as Vue & { reset: () => void }).reset();
      this.isSaveEnabled = false;
    },
  },
});
