
import NewQuoteDialog from "@/components/NewQuoteDialog.vue";
import Vue from "vue";
export default Vue.extend({
  name: "LandingPage",
  components: { NewQuoteDialog },
  methods: {},
  data() {
    return {
      showQuoteDialog: false as boolean,
      showSuccessSnackbar: false as boolean,
      heroIntro:
        "Locally owned and operated, we specialize in hassle-free inter city moving within the North Island. Our commitment to the community drives us to provide the most convenient and affordable moving solutions, guaranteed to beat any competitor's quote.",
      heroTitle: "Your Trusted Moving Partner in New Zealand",
      services: [
        {
          title: "Inter City Moving",
          description:
            "We specialize in seamless inter city moving services, ensuring your belongings reach their destination safely and on time.",
          link: "/inter-city-moving",
        },
        {
          title: "North Island Destinations",
          description:
            "Our coverage extends to anywhere in the North Island, offering you a convenient moving experience no matter the location.",
          link: "/service-destinations",
        },
        {
          title: "Best Price Guarantee",
          description:
            "We guarantee to beat any quote from other moving companies, providing you with the best value for your move.",
          link: "/moving-prices",
        },
      ],
    };
  },
});
