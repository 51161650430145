
import Vue from "vue";
import LandingPage from "../components/LandingPage.vue";

export default Vue.extend({
  name: "PackageAndMoveHome",

  components: {
    LandingPage,
  },
});
