import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/PackageAndMoveHome.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "About us",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/inter-city-moving",
    name: "inter-city-moving",
    meta: {
      title: "Inter city services",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InterCityMoving.vue"),
  },
  {
    path: "/moving-prices",
    name: "moving-prices",
    meta: {
      title: "Price Gaurantee",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BestPriceGaurantee.vue"),
  },
  {
    path: "/service-destinations",
    name: "service-destinations",
    meta: {
      title: "Service Destinations",
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/NorthIslandDestinations.vue"
      ),
  },
  {
    path: "/services",
    name: "services",
    meta: {
      title: "Our Service",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ServicesView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
