import { db } from "@/plugins/firebase/firebaseInit";
import { addDoc, collection } from "firebase/firestore";
import { Quote } from "./quote";
import { ResidentialTypeToDescription } from "./enums/residential-moving-type";
import { MovingTypeToDescription } from "./enums/moving-type";

export default {
  async saveQuote(quote: Quote) {
    const quoteCollection = collection(db, "quotes");
    return await addDoc(quoteCollection, {
      firstName: quote.firstName,
      lastName: quote.lastName,
      description: quote.description || null,
      email: quote.email,
      phone: quote.phone,
      locationFrom: quote.locationFrom,
      locationTo: quote.locationTo,
      movingDate: quote.movingDate,
      movingType: MovingTypeToDescription.GetDescription(quote.movingType),
      residentialMovingType: quote.residentialMovingType
        ? ResidentialTypeToDescription.GetDescription(
            quote.residentialMovingType
          )
        : null,
    });
  },
};
